import extend from 'extend';
import _ from 'underscore';

export default {
  components: {},
  data() {
    return {
      input: ``,
      all_entity_list: [],
      entity_list: [],
    };
  },
  methods: {

    //<editor-fold desc="input">
    __input_click_event_handler() {
      const __this = this;
    },

    __input_blur_event_handler() {
      const __this = this;
      // __this.panel_city_list_for_select.show = false;
    },

    __input_focus_event_handler() {
      const __this = this;
    },

    __input_keyup_event_handler($event) {
      const __this = this;
      const code = $event.code;
      const __input = (__this.input || ``).toLowerCase();
      console.log($event);
      console.log(`__input=${__input}`);

      if (!_.contains([`ArrowDown`, `ArrowUp`], code)) {
        //不是向下箭头并且不是向上箭头，进行初始化数据源

        //TODO:找一下vue的数组双向绑定原理
        //结论：感觉vue的数组赋值时，必须要对数组进行深拷贝（重新开辟内存空间），双向绑定才能生效！

        const f_not_ok_001 = function () {
          const aaa = __this.all_entity_list
            .filter(function (m) {
              // const firstLetter = (m.firstLetter || ``).toLowerCase();
              const code = (m.code || ``).toLowerCase();
              const name = (m.name || ``).toLowerCase();

              let flag = false;
              flag = flag || (name.indexOf(__input) > -1);
              flag = flag || (code.indexOf(__input) > -1);
              return flag;
            })
            .map(function (m) {
              m.active = false;
              return m;
            })
          ;
          __this.entity_list = aaa;
        };
        // f_not_ok_001();

        const f_ok_001 = function () {
          const aaa = __this.all_entity_list
            .filter(function (m) {
              // const firstLetter = (m.firstLetter || ``).toLowerCase();
              const code = (m.code || ``).toLowerCase();
              const name = (m.name || ``).toLowerCase();

              let flag = false;
              flag = flag || (name.indexOf(__input) > -1);
              flag = flag || (code.indexOf(__input) > -1);
              return flag;
            })
            .map(function (m) {
              m.active = false;
              return m;
            })
          ;

          const bbb = JSON.parse(JSON.stringify(aaa));
          __this.entity_list = bbb;
        };
        // f_ok_001();

        const f_ok_002 = function () {
          const ccc = _.chain(__this.all_entity_list)
            .filter(function (m) {
              // const firstLetter = (m.firstLetter || ``).toLowerCase();
              const code = (m.code || ``).toLowerCase();
              const name = (m.name || ``).toLowerCase();

              let flag = false;
              flag = flag || (name.indexOf(__input) > -1);
              flag = flag || (code.indexOf(__input) > -1);
              return flag;
            })
            .map(function (m) {
              const o = extend(true, {}, m);
              o.active = false;
              return o;
            })
            .value()
          ;
          __this.entity_list = ccc;
        };
        f_ok_002();
      }

      //向下箭头
      if (code === `ArrowDown`) {
        const index_entity_active_001 = _.chain(__this.entity_list)
          .findIndex(function (m) {
            return m.active;
          })
          .value()
        ;
        let index_entity_active_002;
        if (index_entity_active_001 === -1) {
          //当前找不到active=true时，下一个为第一个
          index_entity_active_002 = 0;
        } else if (index_entity_active_001 === (__this.entity_list.length - 1)) {
          //当前为最后一个，下一个为第一个
          index_entity_active_002 = 0;
        } else {
          index_entity_active_002 = index_entity_active_001 + 1;
        }

        if (index_entity_active_001 === -1) {
          //当前找不到active=true时，不需要重置上一个的active
          __this.entity_list[index_entity_active_002].active = true;
        } else if (index_entity_active_001 === (__this.entity_list.length - 1)) {
          //当前为最后一个，需要重置上一个的active
          __this.entity_list[index_entity_active_001].active = false;
          __this.entity_list[index_entity_active_002].active = true;
        } else {
          //需要重置上一个的active
          __this.entity_list[index_entity_active_001].active = false;
          __this.entity_list[index_entity_active_002].active = true;
        }
      }

      //向上箭头
      if (code === `ArrowUp`) {
        const index_entity_active_001 = _.chain(__this.entity_list)
          .findIndex(function (m) {
            return m.active;
          })
          .value()
        ;
        let index_entity_active_002;
        if (index_entity_active_001 === -1) {
          //当前找不到active=true时，下一个为最后一个
          index_entity_active_002 = __this.entity_list.length - 1;
        } else if (index_entity_active_001 === 0) {
          //当前为第一个，下一个为最后一个
          index_entity_active_002 = __this.entity_list.length - 1;
        } else {
          index_entity_active_002 = index_entity_active_001 - 1;
        }

        if (index_entity_active_001 === -1) {
          //当前找不到active=true时，不需要重置上一个的active
          __this.entity_list[index_entity_active_002].active = true;
        } else if (index_entity_active_001 === 0) {
          //当前为第一个，需要重置上一个的active
          __this.entity_list[index_entity_active_001].active = false;
          __this.entity_list[index_entity_active_002].active = true;
        } else {
          __this.entity_list[index_entity_active_001].active = false;
          __this.entity_list[index_entity_active_002].active = true;
        }
      }

      console.log(__this.entity_list);
    },
    //</editor-fold>

    __btn_test_click() {
      const __this = this;
    },
  },
  created() {
    const __this = this;
    const all_entity_list = [
      {code: `aaa`, name: `aaa`},
      {code: `aab`, name: `aab`},
      {code: `aac`, name: `aac`},
      {code: `bbb`, name: `bbb`},
      {code: `bbc`, name: `bbc`},
      {code: `bbd`, name: `bbd`},
    ];
    __this.all_entity_list = all_entity_list;
    __this.entity_list = _.chain(all_entity_list)
      .map(function (m) {
        const o = extend(true, {}, m);
        o.active = false;
        return o;
      })
      .value()
    ;
    window.__this = __this;
  },
  activated() {
    // const __this = this;
  },
  deactivated() {
    // const __this = this;
  },
}
